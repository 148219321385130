import { render, staticRenderFns } from "./AddLevel.vue?vue&type=template&id=628d5a68&scoped=true&"
import script from "./AddLevel.vue?vue&type=script&lang=js&"
export * from "./AddLevel.vue?vue&type=script&lang=js&"
import style0 from "./AddLevel.vue?vue&type=style&index=0&id=628d5a68&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "628d5a68",
  null
  
)

export default component.exports