<template>
  <div class="bgc-white p-20 bd" id="mainContent">
    <h4 class="c-grey-900">إضافة مرحلة</h4>
    <div class="mT-30">
      <form @submit="checkForm">
        <div class="form-row mT-20">
          <div class="form-group col-md-12">
            <label for="inputEmail4">إسم المرحلة</label
            ><input type="name" class="form-control" id="inputEmail4" v-model="levelName" required />
          </div>
        </div>

        <div class="form-row mT-20">
          <loading v-show="loading" />
        <sui-button
          type="submit"
          positive
          class="btn btn-primary mL-10"
          :content="$t('add')"
          icon="check"
          label-position="left"
          :disabled="loading"
          v-show="!loading"
        />
        <sui-button
          type="button"
          :content="$t('cancel')"
          :disabled="loading"
          v-show="!loading"
          @click="$emit('closePanel', {})"
        />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reload: { type: Function },
  },
  data() {
    return {
      levelName: "",
      loading: false
    }
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();
      this.loading = true;
      this.addLevel();
    },
    addLevel: function () {
      let headers = {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      };
      this.$http
        .post(
          "https://yalla-emtihan.com/backend/levels/addLevel",
          {
            ar_name: this.levelName,
            en_name: "No Name"
          },
          { headers }
        )

        .then(
          function (data) {
            this.loading = false;
            switch (data.status) {
              case 201:
                this.$emit("closePanel", {});
                this.success(this.$t("success"));
                this.reload();
                break;
            } 
          },
          (err) => {
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
            }
          }
        );
    },
    error: function (message) {
      this.$toast.error(message, {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    success: function () {
      this.$toast.success(this.$t("success"), {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
};
</script>

<style scoped>
#mainContent {
  text-align: right;
  direction: rtl;
}

</style>